import React from "react";
import Layout from "../components/Layout";

import "../styles/pages/tos.scss";

class TermsofServiceIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} pageName="Terms of Service">
        <h1>
          Golden Voice Technologies <span>Terms of Service</span>
        </h1>
        <p>Last Updated: 09/23/2020</p>
        <p>
          Welcome, and thank you for your interest in Golden Voice Technologies
          Inc (“<b>Golden Voice</b>,” “<b>we</b>,” or “<b>us”</b>) and our
          website at http://goldenvoicetechnologies.com/, along with our related
          websites, networks, applications, mobile applications, and other
          services provided by us (collectively, the “Service”). These Terms of
          Service are a legally binding contract between you and Golden Voice
          regarding your use of the Service.
        </p>
        <h2>PLEASE READ THE FOLLOWING TERMS CAREFULLY.</h2>
        <p>
          <b>
            BY CLICKING “I ACCEPT,” OR BY DOWNLOADING, INSTALLING, OR OTHERWISE
            ACCESSING OR USING THE SERVICE
          </b>
          , YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO
          YOUR USE OF THE SERVICE, YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS
          AND CONDITIONS, INCLUDING GOLDEN VOICE’S PRIVACY POLICY (TOGETHER,
          THESE “TERMS”). IF YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO THE TERMS,
          THEN YOU DO NOT HAVE OUR PERMISSION TO USE THE SERVICE. YOUR USE OF
          THE SERVICE, AND GOLDEN VOICE’S PROVISION OF THE SERVICE TO YOU,
          CONSTITUTES AN AGREEMENT BY GOLDEN VOICE AND BY YOU TO BE BOUND BY
          THESE TERMS.
        </p>
        <p>
          <b>ARBITRATION NOTICE.</b> Except for certain kinds of disputes
          described in Section 16, you agree that disputes arising under these
          Terms will be resolved by binding, individual arbitration, and BY
          ACCEPTING THESE TERMS, YOU AND GOLDEN VOICE ARE EACH WAIVING THE RIGHT
          TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR
          REPRESENTATIVE PROCEEDING. YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO
          COURT to assert or defend your rights under this contract (except for
          matters that may be taken to small claims court). Your rights will be
          determined by a NEUTRAL ARBITRATOR and NOT a judge or jury. (See
          Section 16.)
        </p>
        <ol>
          <li>
            <p>
              <b>Golden Voice Service Overview.</b> We offer audio services to
              users to connect more easily and quickly with friends and other
              people from the communities they care about
            </p>
          </li>
          <li>
            <p>
              <b>Eligibility.</b> You must be at least 13 years old to use the
              Service. By agreeing to these Terms, you represent and warrant to
              us that: (a) you are at least 13 years old; (b) if you are under
              18 years old, you have consent from a parent or guardian to use
              the Service and will ony use the Service under the supervision of
              a parent or guardian, (c) you have not previously been suspended
              or removed from the Service; and (d) your registration and your
              use of the Service is in compliance with 2 any and all applicable
              laws and regulations. If you are an entity, organization, or
              company, the individual accepting these Terms on your behalf
              represents and warrants that they have authority to bind you to
              these Terms and you agree to be bound by these Terms.
            </p>
          </li>
          <li>
            <p>
              <b>Accounts and Registration.</b> To access most features of the
              Service, you must register for an account. When you register for
              an account, you may be required to provide us with some
              information about yourself, such as your name, phone number, or
              other contact information. You agree that the information you
              provide to us is accurate and that you will keep it accurate and
              up-to-date at all times. When you register, you will be asked to
              provide a password. You are solely responsible for maintaining the
              confidentiality of your account and password, and you accept
              responsibility for all activities that occur under your account.
              If you believe that your account is no longer secure, then you
              must immediately notify us at support@goldenvoicetechnologies.com.
            </p>
          </li>
          <li>
            <p>
              <b>Licenses</b>
            </p>
            <ol>
              <li>
                <p>
                  <b>Limited License.</b> Subject to your complete and ongoing
                  compliance with these Terms, Golden Voice grants you, solely
                  for your personal, noncommercial use, a limited,
                  non-exclusive, non-transferable, nonsublicensable, revocable
                  license to: (a) install and use one object code copy of any
                  mobile application associated with the Service obtained from a
                  legitimate marketplace (whether installed by you or
                  pre-installed on your mobile device by the device
                  manufacturer) on a mobile device that you own or control; and
                  (b) access and use the Service.
                </p>
              </li>
              <li>
                <p>
                  <b>License Restrictions.</b> Except and solely to the extent
                  such a restriction is impermissible under applicable law, you
                  may not: (a) reproduce, distribute, publicly display, or
                  publicly perform the Service; (b) make modifications to the
                  Service; or (c) interfere with or circumvent any feature of
                  the Service, including any security or access control
                  mechanism. If you are prohibited under applicable law from
                  using the Service, you may not use it.
                </p>
              </li>
              <li>
                <p>
                  <b>Feedback.</b> If you choose to provide input and
                  suggestions regarding problems with or proposed modifications
                  or improvements to the Service (“<b>Feedback</b>""), then you
                  hereby grant Golden Voice an unrestricted, perpetual,
                  irrevocable, non-exclusive, fully-paid, royalty-free right to
                  exploit the Feedback in any manner and for any purpose,
                  including to improve the Service and create other products and
                  services.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <b>Ownership; Proprietary Rights.</b>The Service is owned and
              operated by Golden Voice. The visual interfaces, graphics, design,
              compilation, information, data, computer code (including source
              code or object code), products, software, services, 3 and all
              other elements of the Service (“<b>Materials”</b>) provided by
              Golden Voice are protected by intellectual property and other
              laws. All Materials included in the Service are the property of
              Golden Voice or its third party licensors. Except as expressly
              authorized by Golden Voice, you may not make use of the Materials.
              Golden Voice reserves all rights to the Materials not granted
              expressly in these Terms.
            </p>
          </li>
          <li>
            <p>
              <b>Third Party Terms</b>
            </p>
            <ol>
              <li>
                <p>
                  <b>Third Party Services and Linked Websites.</b> Golden Voice
                  may provide tools through the Service that enable you to
                  export information, including User Content, to third party
                  services , including through features that allow you to link
                  your account on Golden Voice with an account on the third
                  party service, such as Twitter or Facebook, or through our
                  implementation of third party buttons (such as “like” or
                  “share” buttons). By using one of these tools, you agree that
                  Golden Voice may transfer that information to the applicable
                  third party service. Third party services are not under Golden
                  Voice’s control, and, to the fullest extent permitted by law,
                  Golden Voice is not responsible for any third party service’s
                  use of your exported information. The Service may also contain
                  links to third party websites. Linked websites are not under
                  Golden Voice’s control, and Golden Voice is not responsible
                  for their content.
                </p>
              </li>
              <li>
                <p>
                  <b>Third Party Software.</b> The Service incorporates third
                  party software components from Agora Lab Inc. By agreeing to
                  these Terms, you also agree to be bound by Agora Lab Inc.’s
                  Terms of Serivce at https://www.agora.io/en/terms-of-service.
                  The Service may also include or incorporate third party
                  software components that are generally available free of
                  charge under licenses granting recipients broad rights to
                  copy, modify, and distribute those components (“
                  <b>Third Party Components</b>”). Although the Service is
                  provided to you subject to these Terms, nothing in these Terms
                  prevents, restricts, or is intended to prevent or restrict you
                  from obtaining Third Party Components under the applicable
                  third party licenses or to limit your use of Third Party
                  Components under those third party licenses.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <b>User Content</b>
            </p>
            <ol>
              <li>
                <p>
                  <b>User Content Generally.</b> Certain features of the Service
                  may permit users to upload content to the Service, including
                  messages, photos, video, images, audio, data, text, and other
                  types of works (“<b>User Content</b>”) and to publish User
                  Content on the Service. You retain any copyright and other
                  proprietary rights that you may hold in the User Content that
                  you post to the Service.
                </p>
              </li>
              <li>
                <p>
                  <b>Limited License Grant to Golden Voice.</b> By providing
                  User Content to or via the Service, you grant Golden Voice a
                  worldwide, non-exclusive, royalty-free, fully paid right and
                  license (with the right to sublicense) to host, store,
                  transfer, display, perform, reproduce, modify for the purpose
                  of formatting for display, and distribute your User Content,
                  in whole or in part, in any media formats and through any
                  media channels now known or hereafter developed.
                </p>
              </li>
              <li>
                <p>
                  <b>Limited License Grant to Other Users.</b> By providing User
                  Content to or via the Service to other users of the Service,
                  you grant those users a nonexclusive license to access and use
                  that User Content as permitted by these Terms and the
                  functionality of the Service.
                </p>
              </li>
              <li>
                <p>
                  <b>User Content Representations and Warranties.</b> Golden
                  Voice disclaims any and all liability in connection with User
                  Content. You are solely responsible for your User Content and
                  the consequences of providing User Content via the Service. By
                  providing User Content via the Service, you affirm, represent,
                  and warrant that:
                  <ol type="a">
                    <li>
                      you are the creator and owner of the User Content, or have
                      the necessary licenses, rights, consents, and permissions
                      to authorize Golden Voice and users of the Service to use
                      and distribute your User Content as necessary to exercise
                      the licenses granted by you in this Section, in the manner
                      contemplated by Golden Voice, the Service, and these
                      Terms;
                    </li>
                    <li>
                      your User Content, and the use of your User Content as
                      contemplated by these Terms, does not and will not: (i)
                      infringe, violate, or misappropriate any third party
                      right, including any copyright, trademark, patent, trade
                      secret, moral right, privacy right, right of publicity, or
                      any other intellectual property or proprietary right; (ii)
                      slander, defame, libel, or invade the right of privacy,
                      publicity or other property rights of any other person; or
                      (iii) cause Golden Voice to violate any law or regulation;
                      and
                    </li>
                    <li>
                      your User Content could not be deemed by a reasonable
                      person to be objectionable, profane, indecent,
                      pornographic, harassing, threatening, embarrassing,
                      hateful, or otherwise inappropriate.
                    </li>
                  </ol>
                </p>
              </li>
              <li>
                <p>
                  <b>User Content Disclaimer.</b> We are under no obligation to
                  edit or control User Content that you or other users post or
                  publish, and will not be in any way responsible or liable for
                  User Content. Golden Voice may, however, at any time and
                  without prior notice, screen, remove, edit, or block any User
                  Content that in our sole judgment violates these Terms or is
                  otherwise objectionable. You understand that when using the
                  Service you will be exposed to User Content from a variety of
                  sources and 5 acknowledge that User Content may be inaccurate,
                  offensive, indecent, or objectionable. You agree to waive, and
                  do waive, any legal or equitable right or remedy you have or
                  may have against Golden Voice with respect to User Content. If
                  notified by a user or content owner that User Content
                  allegedly does not conform to these Terms, we may investigate
                  the allegation and determine in our sole discretion whether to
                  remove the User Content, which we reserve the right to do at
                  any time and without notice. For clarity, Golden Voice does
                  not permit copyright-infringing activities on the Service.
                </p>
              </li>
              <li>
                <p>
                  <b>Monitoring Content.</b> Golden Voice does not control and
                  does not have any obligation to monitor: (a) User Content; (b)
                  any content made available by third parties; or (c) the use of
                  the Service by its users. You acknowledge and agree that
                  Golden Voice reserves the right to, and may from time to time,
                  monitor any and all information transmitted or received
                  through the Service for operational and other purposes. If at
                  any time Golden Voice chooses to monitor the content, Golden
                  Voice still assumes no responsibility or liability for content
                  or any loss or damage incurred as a result of the use of
                  content. During monitoring, information may be examined,
                  recorded, copied, and used in accordance with our Privacy
                  Policy.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <b>Communications.</b>
            </p>
            <ol>
              <li>
                <p>
                  <b>Text Messaging.</b> Golden Voice and those acting on our
                  behalf may send you text (SMS) messages at the phone number
                  you provide us. These messages may include operational
                  messages about your use of the Service, as well as marketing
                  messages. You may opt out of receiving marketing text messages
                  at any time by sending an email to
                  support@goldenvoicetechnologies.com indicating that you no
                  longer wish to receive marketing texts along with the phone
                  number of the mobile device receiving the texts. You may
                  continue to receive text messages for a short period while we
                  process your request, and you may also receive text messages
                  confirming the receipt of your opt-out request. Operational
                  text messages are essential to the Service. If you do not wish
                  to receive operational text messages from us, do not use the
                  Service. Text messages may be sent using an automatic
                  telephone dialing system. Your agreement to receive marketing
                  text messages is not a condition of any purchase or use of the
                  Service. Standard data and message rates may apply whenever
                  you send or receive such messages, as specified by your
                  carrier.
                </p>
              </li>
              <li>
                <p>
                  <b>Push Notifications.</b> When you install our app on your
                  mobile device, you agree to receive push notifications, which
                  are messages an app sends 6 you on your mobile device when the
                  app is not on. You can turn off notifications by visiting your
                  mobile device’s “settings” page.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <b>Prohibited Conduct.</b>BY USING THE SERVICE YOU AGREE NOT TO:
              <ol type="a">
                <li>
                  use the Service for any illegal purpose or in violation of any
                  local, state, national, or international law;
                </li>
                <li>
                  harass, threaten, demean, embarrass, or otherwise harm any
                  other user of the Service;
                </li>
                <li>
                  record audio in violation of any local, state, national, or
                  international law;
                </li>
                <li>
                  violate, or encourage others to violate, any right of a third
                  party, including by infringing or misappropriating any third
                  party intellectual property right;
                </li>
                <li>
                  interfere with security-related features of the Service,
                  including by: (i) disabling or circumventing features that
                  prevent or limit use or copying of any content; or (ii)
                  reverse engineering or otherwise attempting to discover the
                  source code of any portion of the Service except to the extent
                  that the activity is expressly permitted by applicable law;
                </li>
                <li>
                  interfere with the operation of the Service or any user’s
                  enjoyment of the Service, including by: (i) uploading or
                  otherwise disseminating any virus, adware, spyware, worm, or
                  other malicious code; (ii) making any unsolicited offer or
                  advertisement to another user of the Service; (iii) collecting
                  personal information about another user or third party without
                  consent; or (iv) interfering with or disrupting any network,
                  equipment, or server connected to or used to provide the
                  Service;
                </li>
                <li>
                  perform any fraudulent activity including impersonating any
                  person or entity, claiming a false affiliation, accessing any
                  other Service account without permission;
                </li>
                <li>
                  sell or otherwise transfer the access granted under these
                  Terms or any Materials (as defined in Section 5) or any right
                  or ability to view, access, or use any Materials; or
                </li>
                <li>
                  attempt to do any of the acts described in this Section 9 or
                  assist or permit any person in engaging in any of the acts
                  described in this Section 9.
                </li>
              </ol>
            </p>
          </li>
          <li>
            <p>
              <b>Digital Millennium Copyright Act</b>
            </p>
            <ol>
              <li>
                <p>
                  <b>DMCA Notification.</b>We comply with the provisions of the
                  Digital Millennium Copyright Act applicable to Internet
                  service providers (17 7 U.S.C. §512, as amended). If you have
                  an intellectual property rightsrelated complaint about
                  material posted on the Service, you may contact our Designated
                  Agent at the following address: Golden Voice Technologies Inc.
                  ATTN: Legal Department (Copyright Notification) 475 Hawthorne
                  Ave, 94301, Palo Alto, CA 650-460-0588 Email:
                  support@goldenvoicetechnologies.com
                </p>
                <p>
                  Any notice alleging that materials hosted by or distributed
                  through the Service infringe intellectual property rights must
                  include the following information:
                  <ol type="a">
                    <li>
                      an electronic or physical signature of the person
                      authorized to act on behalf of the owner of the copyright
                      or other right being infringed;
                    </li>
                    <li>
                      a description of the copyrighted work or other
                      intellectual property that you claim has been infringed;
                    </li>
                    <li>
                      a description of the material that you claim is infringing
                      and where it is located on the Service;
                    </li>
                    <li> your address, telephone number, and email address;</li>
                    <li>
                      a statement by you that you have a good faith belief that
                      the use of the materials on the Service of which you are
                      complaining is not authorized by the copyright owner, its
                      agent, or the law; and
                    </li>
                    <li>
                      statement by you that the above information in your notice
                      is accurate and that, under penalty of perjury, you are
                      the copyright or intellectual property owner or authorized
                      to act on the copyright or intellectual property owner’s
                      behalf.
                    </li>
                  </ol>
                </p>
              </li>
              <li>
                <p>
                  <b>Repeat Infringers.</b> Golden Voice will promptly terminate
                  the accounts of users that are determined by Golden Voice to
                  be repeat infringers.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <b>Modification of these Terms.</b> We reserve the right to change
              these Terms on a going-forward basis at any time. Please check
              these Terms periodically for changes. If a change to these Terms
              materially modifies your rights or obligations, we may require
              that you accept the modified Terms in order to continue to use the
              Service. Material modifications are effective upon your acceptance
              of the modified Terms. Immaterial modifications are effective upon
              publication. Except as expressly permitted in this Section 11,
              these Terms may be amended only by a written agreement signed by
              authorized representatives of the parties to these Terms. 8
              Disputes arising under these Terms will be resolved in accordance
              with the version of these Terms that was in effect at the time the
              dispute arose.
            </p>
          </li>
          <li>
            <p>
              <b>Term, Termination and Modification of the Service</b>
            </p>
            <ol>
              <li>
                <p>
                  <b>Term.</b> These Terms are effective beginning when you
                  accept the Terms or first download, install, access, or use
                  the Service, and ending when terminated as described in
                  Section 12.2.
                </p>
              </li>
              <li>
                <p>
                  <b>Termination.</b> If you violate any provision of these
                  Terms, your authorization to access the Service and these
                  Terms automatically terminate. In addition, Golden Voice may,
                  at its sole discretion, terminate these Terms or your account
                  on the Service, or suspend or terminate your access to the
                  Service, at any time for any reason or no reason, with or
                  without notice. You may terminate your account and these Terms
                  at any time by tapping on the options button in your profile
                  and choosing to delete your profile or contacting customer
                  service at support@goldenvoicetechnologies.com.
                </p>
              </li>
              <li>
                <p>
                  <b>Effect of Termination.</b> Upon termination of these Terms:
                  (a) your license rights will terminate and you must
                  immediately cease all use of the Service; (b) you will no
                  longer be authorized to access your account or the Service;
                  (c) you must pay Golden Voice any unpaid amount that was due
                  prior to termination; and (d) all payment obligations accrued
                  prior to termination and Sections 4.3, 5, 12.3, 14, 15, 16,
                  and 17 will survive.
                </p>
              </li>
              <li>
                <p>
                  <b>Modification of the Service.</b> Golden Voice reserves the
                  right to modify or discontinue the Service at any time
                  (including by limiting or discontinuing certain features of
                  the Service), temporarily or permanently, without notice to
                  you. Golden Voice will have no liability for any change to the
                  Service or any suspension or termination of your access to or
                  use of the Service.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <b>Indemnity.</b> To the fullest extent permitted by law, you are
              responsible for your use of the Service, and you will defend and
              indemnify Golden Voice and its officers, directors, employees,
              consultants, affiliates, subsidiaries and agents (together, the “
              <b>Golden Voice Entities</b>”) from and against every claim
              brought by a third party, and any related liability, damage, loss,
              and expense, including reasonable attorneys’ fees and costs,
              arising out of or connected with: (a) your unauthorized use of, or
              misuse of, the Service; (b) your violation of any portion of these
              Terms, any representation, warranty, or agreement referenced in
              these Terms, or any applicable law or regulation; (c) your
              violation of any third party right, including any intellectual
              property right or publicity, confidentiality, other property, or
              privacy right; or (d) any dispute or issue between you and any
              third party. We reserve the right, at 9 our own expense, to assume
              the exclusive defense and control of any matter otherwise subject
              to indemnification by you (without limiting your indemnification
              obligations with respect to that matter), and in that case, you
              agree to cooperate with our defense of those claims.
            </p>
          </li>
          <li>
            <p>
              <b>Disclaimers; No Warranties</b>
            </p>
            <p>
              THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE
              SERVICE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS.
              GOLDEN VOICE DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
              OR IMPLIED, RELATING TO THE SERVICE AND ALL MATERIALS AND CONTENT
              AVAILABLE THROUGH THE SERVICE, INCLUDING: (A) ANY IMPLIED WARRANTY
              OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET
              ENJOYMENT, OR NON-INFRINGEMENT; AND (B) ANY WARRANTY ARISING OUT
              OF COURSE OF DEALING, USAGE, OR TRADE. GOLDEN VOICE DOES NOT
              WARRANT THAT THE SERVICE OR ANY PORTION OF THE SERVICE, OR ANY
              MATERIALS OR CONTENT OFFERED THROUGH THE SERVICE, WILL BE
              UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER
              HARMFUL COMPONENTS, AND GOLDEN VOICE DOES NOT WARRANT THAT ANY OF
              THOSE ISSUES WILL BE CORRECTED. NO ADVICE OR INFORMATION, WHETHER
              ORAL OR WRITTEN, OBTAINED BY YOU FROM THE SERVICE OR GOLDEN VOICE
              ENTITIES OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICE
              WILL CREATE ANY WARRANTY REGARDING ANY OF THE GOLDEN VOICE
              ENTITIES OR THE SERVICE THAT IS NOT EXPRESSLY STATED IN THESE
              TERMS. WE ARE NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM
              THE SERVICE AND YOUR DEALING WITH ANY OTHER SERVICE USER. YOU
              UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE SERVICE AT
              YOUR OWN DISCRETION AND RISK, AND THAT WE ARE NOT RESPONSIBLE FOR
              ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR
              MOBILE DEVICE USED IN CONNECTION WITH THE SERVICE) OR ANY LOSS OF
              DATA, INCLUDING USER CONTENT. THE LIMITATIONS, EXCLUSIONS AND
              DISCLAIMERS IN THIS SECTION APPLY TO THE FULLEST EXTENT PERMITTED
              BY LAW. Golden Voice does not disclaim any warranty or other right
              that Golden Voice is prohibited from disclaiming under applicable
              law.
            </p>
          </li>
          <li>
            <p>
              <b>Limitation of Liability</b>
            </p>
            <p>
              TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE
              GOLDEN VOICE ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, 10
              INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING
              DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE
              LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR
              YOUR INABILITY TO ACCESS OR USE, THE SERVICE OR ANY MATERIALS OR
              CONTENT ON THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT
              (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, AND
              WHETHER OR NOT ANY GOLDEN VOICE ENTITY HAS BEEN INFORMED OF THE
              POSSIBILITY OF DAMAGE. EXCEPT AS PROVIDED IN SECTION 16.5 AND TO
              THE FULLEST EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY OF
              THE GOLDEN VOICE ENTITIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR
              RELATING TO THE USE OF OR ANY INABILITY TO USE ANY PORTION OF THE
              SERVICE OR OTHERWISE UNDER THESE TERMS, WHETHER IN CONTRACT, TORT,
              OR OTHERWISE, IS LIMITED TO THE GREATER OF: (A) THE AMOUNT YOU
              HAVE PAID TO GOLDEN VOICE FOR ACCESS TO AND USE OF THE SERVICE IN
              THE 12 MONTHS PRIOR TO THE EVENT OR CIRCUMSTANCE GIVING RISE TO
              CLAIM; OR (B) $100. EACH PROVISION OF THESE TERMS THAT PROVIDES
              FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR
              EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS
              BETWEEN THE PARTIES UNDER THESE TERMS. THIS ALLOCATION IS AN
              ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES.
              EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER
              PROVISIONS OF THESE TERMS. THE LIMITATIONS IN THIS SECTION 15 WILL
              APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
            </p>
          </li>
          <li>
            <p>
              <b>Dispute Resolution and Arbitration</b>
            </p>
            <ol>
              <li>
                <p>
                  <b>Generally.</b> In the interest of resolving disputes
                  between you and Golden Voice in the most expedient and cost
                  effective manner, and except as described in Section 16.2 and
                  16.3, you and Golden Voice agree that every dispute arising in
                  connection with these Terms will be resolved by binding
                  arbitration. Arbitration is less formal than a lawsuit in
                  court. Arbitration uses a neutral arbitrator instead of a
                  judge or jury, may allow for more limited discovery than in
                  court, and can be subject to very limited review by courts.
                  Arbitrators can award the same damages and relief that a court
                  can award. This agreement to arbitrate disputes includes all
                  claims arising out of or relating to any aspect of these
                  Terms, whether based in contract, tort, statute, fraud,
                  misrepresentation, or any other legal theory, and regardless
                  of whether a claim arises during or after the termination of
                  these Terms. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO
                  THESE TERMS, YOU AND GOLDEN VOICE ARE EACH WAIVING 11 THE
                  RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
                </p>
              </li>
              <li>
                <p>
                  <b>Exceptions.</b> Despite the provisions of Section 16.1,
                  nothing in these Terms will be deemed to waive, preclude, or
                  otherwise limit the right of either party to: (a) bring an
                  individual action in small claims court; (b) pursue an
                  enforcement action through the applicable federal, state, or
                  local agency if that action is available; (c) seek injunctive
                  relief in a court of law in aid of arbitration; or (d) to file
                  suit in a court of law to address an intellectual property
                  infringement claim.
                </p>
              </li>
              <li>
                <p>
                  <b>Opt-Out.</b> If you do not wish to resolve disputes by
                  binding arbitration, you may opt out of the provisions of this
                  Section 16 within 30 days after the date that you agree to
                  these Terms by sending a letter to Golden Voice Technologies
                  Inc., Attention: Legal Department – Arbitration Opt-Out, 475
                  Hawthorne Ave, 94301, Palo Alto, CA that specifies: your full
                  legal name, the email address associated with your account on
                  the Service, and a statement that you wish to opt out of
                  arbitration (“<b>Opt-Out Notice</b>”). Once Golden Voice
                  receives your Opt-Out Notice, this Section 16 will be void and
                  any action arising out of these Terms will be resolved as set
                  forth in Section 17.2. The remaining provisions of these Terms
                  will not be affected by your Opt-Out Notice.
                </p>
              </li>
              <li>
                <p>
                  <b> Arbitrator.</b>Any arbitration between you and Golden
                  Voice will be settled under the Federal Arbitration Act and
                  administered by the American Arbitration Association (“
                  <b>AAA</b>") under its Consumer Arbitration Rules
                  (collectively, “<b>AAA Rules</b>”) as modified by these Terms.
                  The AAA Rules and filing forms are available online at
                  www.adr.org, by calling the AAA at 1-800-778-7879, or by
                  contacting Golden Voice. The arbitrator has exclusive
                  authority to resolve any dispute relating to the
                  interpretation, applicability, or enforceability of this
                  binding arbitration agreement.
                </p>
              </li>
              <li>
                <p>
                  <b>Notice of Arbitration; Process.</b>A party who intends to
                  seek arbitration must first send a written notice of the
                  dispute to the other party by certified U.S. Mail or by
                  Federal Express (signature required) or, only if that other
                  party has not provided a current physical address, then by
                  electronic mail (“<b>Notice of Arbitration</b>”). Golden
                  Voice’s address for Notice is: Golden Voice, 475 Hawthorne
                  Ave, 94301, Palo Alto, CA. The Notice of Arbitration must: (a)
                  describe the nature and basis of the claim or dispute; and (b)
                  set forth the specific relief sought (“<b>Demand</b>"). The
                  parties will make good faith efforts to resolve the claim
                  directly, but if the parties do not reach an agreement to do
                  so within 30 days after the Notice of Arbitration is received,
                  you or Golden Voice may commence an arbitration proceeding.
                  All arbitration proceedings between the parties will be 12
                  confidential unless otherwise agreed by the parties in
                  writing. During the arbitration, the amount of any settlement
                  offer made by you or Golden Voice must not be disclosed to the
                  arbitrator until after the arbitrator makes a final decision
                  and award, if any. If the arbitrator awards you an amount
                  higher than the last written settlement amount offered by
                  Golden Voice in settlement of the dispute prior to the award,
                  Golden Voice will pay to you the higher of: (i) the amount
                  awarded by the arbitrator; or (ii) $10,000.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </Layout>
    );
  }
}

export default TermsofServiceIndex;
